import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';
import SendCard from 'components/cards/send';
import QRCard from 'components/cards/qrcode';
import SkeletonCard from 'components/cards/skeleton';

import {
  REWARD, SHARE, APPLE, ANDROID,
} from 'constants/font-awesome';
import {
  LOYALTY_ROUTE, LOYALTY_SHARE_ROUTE,
  LOYALTY_SHARE_IOS_ROUTE, LOYALTY_SHARE_ANDROID_ROUTE,
} from 'constants/navigation';

import { ONCION_API } from 'gatsby-env-variables';
import { IOS_GET_PASS } from 'constants/urls';

import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const ShareView = ({ passId, title, token }) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(true);
  const [iOSUrl, setIosUrl] = useState('');

  useEffect(() => {
    async function fetchPassData() {
      const getPassUrlService = await import('services/passes/pass-url-service');
      const { getShortUrl } = getPassUrlService.default();
      const url = await getShortUrl({ token, type: 'ios' });
      setIosUrl(url);
      setLoading(false);
    }
    fetchPassData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
    <div className={styles.menucontent}>
      <Grid spacing={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
        <Grid item md={4} sm={6} xs={12}>
        {loading ? (
          <SkeletonCard button qr/>
        ) : (
          <SendCard
            type={APPLE}
            name={title}
            url={iOSUrl}
          />
        )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <QRCard
            description="Scan to add to Apple Wallet"
            url={`${ONCION_API}${IOS_GET_PASS}${token}`}
          />
          </Grid>
      </Grid>
    </div>
    <LazyOverrideFooter
      navItems={[
        {
          route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`,
          icon: SHARE,
          message: 'Share',
        },
        {
          route: `${LOYALTY_SHARE_IOS_ROUTE}?passId=${passId}`,
          icon: APPLE,
          message: 'iOS',
        },
        {
          route: `${LOYALTY_SHARE_ANDROID_ROUTE}?passId=${passId}`,
          icon: ANDROID,
          message: 'Android',
        },
        { route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: REWARD, message: 'Loyalty' },
      ]}
      currentItem="iOS"/>
      </>
  );
};

ShareView.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
  token: string.isRequired,
};

export default ShareView;
